import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My dear friends, please don’t ever trade happiness for sympathy. Don’t let your
struggle become your identity. Struggles were meant to be conquered. The
struggling itself gives us the strength to defeat the struggle.`}</p>
    <p>{`“You are heavens handmade calligraphy...” Let us not settle to be anything less
than what The divine artist intended us to be.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      